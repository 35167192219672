import { Col, Row } from "antd"
import Header from "./AuthCMS/Header"
import Sidebar from "./AuthCMS/Sidebar"

const AuthCMSAntd = ({ tabActive, children }) => {
    return (<>
        <Row>
            <Col xs={0} sm={0} lg={4}>
                <Sidebar tabActive={tabActive} />
            </Col>
            <Col xs={24} sm={24} lg={20}>
                <Header />
                    <div className="main-content flex flex-col flex-grow p-4 w-full">
                        <div
                            className="flex flex-col flex-grow bg-white rounded mt-4"
                        >
                            {children}
                        </div>
                    </div>
                    <footer className="footer px-4 py-6">
                        <div className="footer-content">
                            <p className="text-sm text-gray-600 text-center">© Proxy Center 2024. All rights reserved.</p>
                        </div>
                    </footer>
            </Col>
        </Row>
    </>)
}

export default AuthCMSAntd