import { Link } from "react-router-dom"
import HomeIcon from "./Icon/Home"
import PackageIcon from "./Icon/Package"
import KeyIcon from "./Icon/Key"
import TopupIcon from "./Icon/Topup"
import PaymentIcon from "./Icon/Payment"
import { useSelector } from "react-redux"
import { getMenu } from "../../Stores/AuthSlice"
import { getUserMeState } from "../../Stores/UserSlice"
import { useEffect } from "react"
import icon from "../../../logo.svg"
import logo from "../../../logo2.png"
import UserIcon from "./Icon/User"
import DocIcon from "./Icon/Doc"
import SettingIcon from "./Icon/Setting"


const tabs = {
    "/dashboard": {
        key: 1,
        name: "Dashboard",
        iconSVG: <HomeIcon />,
        path: "/dashboard",
    },
    "Parent-1": {
        key: 2,
        name: "Proxy Service",
    },
    "/package": {
        key: 3,
        name: "Package",
        iconSVG: <PackageIcon />,
        path: "/package",
    },
    "/key": {
        key: 4,
        name: "Key",
        iconSVG: <KeyIcon />,
        path: "/key",
    },
    "Parent-2": {
        key: 5,
        name: "Transaction",
    },
    "/topup": {
        key: 6,
        name: "Topup",
        iconSVG: <TopupIcon />,
        path: "/topup",
    },
    "/payment": {
        key: 7,
        name: "Payment",
        iconSVG: <PaymentIcon />,
        path: "/payment",
    },
    "Parent-3": {
        key: 8,
        name:"Management"
    },
    "/user": {
        key: 9,
        name: "User",
        iconSVG: <UserIcon />,
        path: "/users",
    },
    "/location": {
        key: 12,
        name: "Location",
        iconSVG: <SettingIcon />,
        path: "/location",
    },
    "/box": {
        key: 13,
        name: "Box",
        iconSVG: <SettingIcon />,
        path: "/box",
    },
    "/line": {
        key: 14,
        name: "Line",
        iconSVG: <SettingIcon />,
        path: "/line",
    },
    "/proxy": {
        key: 15,
        name: "Proxy",
        iconSVG: <SettingIcon />,
        path: "/proxy",
    },
    "Parent-4": {
        key: 10,
        name: "ACCESS"
    },
    "/doc":{
        key: 11,
        name: "Doc",
        iconSVG: <DocIcon />,
        path: "/doc",
    },
}

export const makeMenu = (menuData) => {
    const menu = []
    // for (const [key, value] of Object.entries(tabs)) {
    //     menuData.forEach(tab => {
    //         if (tab.path == key) {
    //             menu.push({ ...value, name: tab.name });
    //         }
    //     });
    //     if (key.indexOf("Parent") !== -1) {
    //         menu.push(value);
    //     }
    // }
    let group = {
        "": [],
    }
    menuData.forEach(tab => {
        if(tab.group) {
            if(!group[tab.group]) {
                group[tab.group] = []
            } 
            let child = tab
            if(tabs[tab.path]) {
                child = tabs[tab.path]
            }
            group[tab.group].push(child)
        } else {
            let child = tab
            if(tabs[tab.path]) {
                child = tabs[tab.path]
            }
            group[""].push(child)
        }
    });

    for (const [key, value] of Object.entries(group)) {
        menu.push({name: key})
        value.map((e) => {
            menu.push(e)
        })
    }
    return menu
}

const Sidebar = ({ tabActive }) => {
    const menu = useSelector(getMenu)
    const me = useSelector(getUserMeState)

    return (
        <aside
            className="sidebar w-60 md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-green-950 flex-shrink-0 h-full"
        >
            <div className="sticky top-0">
            <div className="sidebar-header flex items-center justify-left px-2 py-3 backdrop-brightness-50">
                <div className="inline-flex">
                    <a href="# " className="inline-flex flex-row items-center">
                        <img
                            alt={process.env.REACT_APP_PROJECT_NAME}
                            src={icon}
                            className="mx-auto h-16 w-auto"
                        />
                        <img
                            alt="Your Company"
                            src={logo}
                            className="mx-auto h-4 w-auto"
                        />
                    </a>
                </div>
            </div>
            <div className="sidebar-content px-4 py-2 overflow-y-auto h-[calc(100vh-88px)]">
                <ul className="flex flex-col w-full">
                    {menu.map((tab) => {
                        let isActive = false
                        if (tabActive == tab.path) {
                            isActive = true
                        }
                        return renderTab({ ...tab, isActive });
                    })}
                    {/* {me.is_superuser && <>
                        <li className="my-px" key={"admin"}>
                            <span className="flex font-medium text-sm text-gray-300 px-4 my-4 uppercase">{"Management"}</span>
                        </li>
                        <li className="my-px" key={"users"}>
                            <Link
                                to={"/users"}
                                className={tabActive =="/users" ? "flex flex-row items-center h-10 px-3 rounded-lg text-white hover:bg-gray-100 hover:text-gray-700 bg-gradient-to-r from-green-600 to-green-400" : "flex flex-row items-center h-10 px-3 rounded-lg text-gray-300 hover:bg-gray-100 hover:text-gray-700"}
                            >
                                <span className="flex items-center justify-center text-lg">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="currentColor"
                                        version="1.1"
                                        viewBox="0 0 512 512"
                                        xmlSpace="preserve"
                                    >
                                        <path d="M333.187 237.405c32.761-23.893 54.095-62.561 54.095-106.123C387.282 58.893 328.389 0 256 0S124.718 58.893 124.718 131.282c0 43.562 21.333 82.23 54.095 106.123-81.44 31.165-139.428 110.126-139.428 202.39 0 39.814 32.391 72.205 72.205 72.205h288.82c39.814 0 72.205-32.391 72.205-72.205 0-92.264-57.988-171.225-139.428-202.39zM164.103 131.282c0-50.672 41.225-91.897 91.897-91.897s91.897 41.225 91.897 91.897S306.672 223.18 256 223.18s-91.897-41.226-91.897-91.898zM400.41 472.615H111.59c-18.097 0-32.82-14.723-32.82-32.821 0-97.726 79.504-177.231 177.231-177.231s177.231 79.504 177.231 177.231c-.001 18.098-14.724 32.821-32.822 32.821z"></path>
                                    </svg>
                                </span>
                                <span className="ml-3">{"Users"}</span>
                            </Link >
                        </li >
                    </>} */}
                </ul>
            </div>

            </div>
        </aside>
    )
}

const renderTab = ({ name, iconSVG, path, isActive }) => {
    let classNameTab = "flex flex-row items-center h-10 px-3 rounded-lg text-gray-300 hover:bg-gray-100 hover:text-gray-700 ";
    if (isActive) {
        classNameTab += "bg-gradient-to-r from-emerald-300 to-emerald-600 text-gray-900 transition-colors duration-300 hover:bg-gradient-to-l hover:from-emerald-400 hover:to-emerald-700 hover:text-white"
    }
    return (
        path ? <li className="my-px" key={name}>
            < Link
                to={path}
                className={classNameTab}
            >
                <span className="flex items-center justify-center text-lg">
                    {iconSVG}
                </span>
                <span className="ml-3">{name}</span>
            </Link >
        </li > :
            <li className="my-px" key={name}>
                <span className="flex font-medium text-sm text-gray-300 px-4 my-4 uppercase">{name}</span>
            </li>
    )
}

export default Sidebar