import { useDispatch, useSelector } from "react-redux"
import BasicModule from "../../Commons/BasicModule"
import AuthCMS from "../../Layouts/AuthCMS"
import { getList, getState } from "../../Stores/Box/Slice"
import { useEffect } from "react"
import AuthCMSAntd from "../../Layouts/AuthCMSAntd"

// Edit
const modelField = ['name', 'description', 'box_serial', 'status', 'identity', 'ip_address', 'port', 'interfaces', 'location_id', 'domain', 'winbox_port', 'ssh_port', 'api_port', 'api_user', 'api_password', 'env', 'mac_address']
const headerInfo = {
    title: "Box",
    description: "List box",
}
const dataList = [
    {id: 1, name: "Example 1"},
    {id: 2, name: "Example 2"}
]

// No Edit 
const columnTable = modelField.map((field) => ({
    title: field.toUpperCase(),
    dataIndex: field,
    key: field,
}))

const Box = () => {
    const data = useSelector(getState)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getList())
    }, [dispatch])
    return <AuthCMSAntd tabActive={"/box"}>
        <BasicModule
            headerInfo={headerInfo}
            columnTable={columnTable}
            dataList={data.boxs}
        />
    </AuthCMSAntd>
}

export default Box