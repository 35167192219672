import axios from "axios"
const HOST = process.env.REACT_APP_API_HOST
const PATH = "/api/v1/lines/lines"

export const createFetch = async (token, dataInput) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.post(HOST + PATH, dataInput, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const getFetch = async (token, search) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + PATH, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const getDetailFetch = async (token, keyID) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + PATH + keyID, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const deleteFetch = async (token, keyID) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.delete(HOST + PATH + keyID, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}