import { useDispatch, useSelector } from "react-redux"
import BasicModule from "../../Commons/BasicModule"
import AuthCMS from "../../Layouts/AuthCMS"
import { getList, getState } from "../../Stores/Location/Slice"
import { useEffect } from "react"

// Edit
const modelField = ['name', 'address', 'city', 'description', 'region', 'env']
const headerInfo = {
    title: "Location",
    description: "List location we were support",
}
const dataList = [
    {id: 1, name: "Example 1"},
    {id: 2, name: "Example 2"}
]

// No Edit 
const columnTable = modelField.map((field) => ({
    title: field.toUpperCase(),
    dataIndex: field,
    key: field,
}))

const Location = () => {
    const data = useSelector(getState)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getList())
    }, [dispatch])

    return <AuthCMS tabActive={"/location"}>
        <BasicModule
            headerInfo={headerInfo}
            columnTable={columnTable}
            dataList={data.locations}
        />
    </AuthCMS>
}

export default Location