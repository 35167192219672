import { useEffect } from "react"
import AuthCMS from "../../Layouts/AuthCMS"
import { getTopupState, onTopupsRequest } from "../../Stores/TopupSlice"
import { useDispatch, useSelector } from "react-redux"
import { getBankInfo, getLangModule } from "../../Stores/AuthSlice"

const Doc = () => {
    const data = useSelector(getTopupState)
    const dataBank = useSelector(getBankInfo)
    const langData = useSelector(getLangModule);
    const { topup: lang } = langData;

    const dispath = useDispatch();
    useEffect(() => {
        dispath(onTopupsRequest());
        const intervalId = setInterval(() => {
            dispath(onTopupsRequest());
        }, 5 * 1000);

        // Cleanup function
        return () => {
            clearInterval(intervalId);
        };
    }, [dispath])

    return <AuthCMS tabActive={"/doc"}>
        <div className="flex flex-col md:flex-row bg-white">
            {/* Left Section - Account Information */}
            <div className="w-full md:w-1/2 bg-white p-12">
                <h2 className="text-lg font-semibold mb-6">{"Tài liệu API"}</h2>
                <h5 class="text-lg font-semibold">{"1. Giới thiệu"}</h5>
                <p className="color-gray">Dữ liệu của Proxy sẽ bao gồm các trường như sau:</p>
                    <ul className="list-disc list-inside">
                        <li>id - ID của Proxy</li>
                        <li>IP Address - Địa chỉ IP thực của proxy đó</li>
                        <li>http - Giá trị của Proxy HTTP, theo định dạng 'ip:port'</li>
                        <li>socks5 - Giá trị của Proxy SOCKS5, theo định dạng 'ip:port'</li>
                        <li>httpPort - Cổng dịch vụ proxy với giao thức HTTP</li>
                        <li>socks5Port - Cổng dịch vụ proxy với giao thức SOCKS5</li>
                        <li>host - Địa chỉ của proxy server</li>
                        <li>location - Địa điểm theo từng khu vực</li>
                        <li>expirationAt - Thời gian proxy sẽ hết hạn</li>
                        <li>ttl - Thời gian sống tối đa của Proxy đó (theo giây)</li>
                        <li>ttc - Thời gian còn lại cho tới lượt đổi Proxy tiếp theo (theo giây)</li>
                    </ul>
                <p>
                    Lưu ý: Chúng tôi sẽ không cung cấp cho các khách hàng lạm dụng dịch vụ, quý khách lưu ý có thể bị chấm dứt hoạt động nếu vi phạm các điều khoản và chính sách của chúng tôi.
                </p>
                <h5 class="text-lg font-semibold">{"2. Tạo token"}</h5>
                <p className="color-gray"><a href="/me">Lấy Token</a></p>
                <h5 class="text-lg font-semibold">{"3. Lấy thông tin Proxy"}</h5>
                <p className="color-gray">API lấy thông tin proxy</p>
                <ul>
                    <li>URL: https://dev-api.proxycenter.net</li>
                    <li>PATH: /public/api/v1/keys</li>
                    <li>Method: GET</li>
                    <li>Param: 
                        <pre>key=[Giá trị key]</pre>
                        <pre>token=[Giá trị token]</pre>
                    </li>
                    <li>Thành công:
                        <pre>
{`{
    "data": [
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "duration": 0,
        "unit": "string",
        "start_date": "2025-01-03T08:14:12.293Z",
        "end_date": "2025-01-03T08:14:12.293Z",
        "status": true,
        "number_cycles": 0,
        "ip": "string",
        "port": 0,
        "socks5": 0,
        "proxy_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "time_refresh": "2025-01-03T08:14:12.293Z",
        "package_name": "string",
        "time_usage": 0,
        "remain_time_usage": 0,
        "minumum_usage": 0,
        "remain_minumum_usage": 0,
        "city_name": "string",
        "day_left": 0                 
    ]
}`}
                        </pre>
                    </li>
                    <li>Lỗi:
                        <pre>
{`{
     "detail": [
        {
        "loc": [
            "string",
            0
        ],
        "msg": "string",
        "type": "string"
        }
    ]
}`}
                        </pre>
                    </li>
                </ul>
                <h5 class="text-lg font-semibold">{"4. Làm mới proxy"}</h5>
                <p className="color-gray">API làm mới proxy</p>
                <ul>
                    <li>URL: https://dev-api.proxycenter.net</li>
                    <li>PATH: /public/api/v1/keys/refresh</li>
                    <li>Method: POST</li>
                    <li>Param: 
                        <pre>key=[Giá trị key]</pre>
                        <pre>token=[Giá trị token]</pre>
                    </li>
                    <li>Thành công:
                        <pre>
{`{
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "duration": 0,
    "unit": "string",
    "start_date": "2025-01-03T08:19:11.285Z",
    "end_date": "2025-01-03T08:19:11.285Z",
    "status": true,
    "number_cycles": 0,
    "ip": "string",
    "port": 0,
    "socks5": 0,
    "proxy_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "time_refresh": "2025-01-03T08:19:11.285Z",
    "package_name": "string",
    "time_usage": 0,
    "remain_time_usage": 0,
    "minumum_usage": 0,
    "remain_minumum_usage": 0,
    "city_name": "string",
    "day_left": 0
}`}
                        </pre>
                    </li>
                    <li>Lỗi:
                        <pre>
{`{ 
    "detail": [
        {
        "loc": [
            "string",
            0
        ],
        "msg": "string",
        "type": "string"
        }
    ]
}`}
                        </pre>
                    </li>
                </ul>
            </div>
        </div>
    </AuthCMS>
}

export default Doc