import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';

const Detail = ({ isOpen, setIsOpenDetail, dataDetail }) => {
    const handleOk = () => {
        setIsOpenDetail(false);
    };
    const handleCancel = () => {
        setIsOpenDetail(false);
    };
    return (
        <Modal title="Edit Item" open={isOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form>
                {Object.keys(dataDetail).map((key, index) => {
                    return <Form.Item
                        key={index}
                        label={key.toUpperCase()}
                    >
                        <Input defaultValue={dataDetail[key]} />
                    </Form.Item>
                })}
            </Form>
        </Modal>
    );

}

export default Detail