import { Button, Card, Col, Form, Input, Row, Spin } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { getUserMeRequest, getUserMeState, getUserStatusState, updateUserMeRequest } from "../../../Stores/UserSlice";
import { useEffect } from "react";

const Account = () => {
    const meData = useSelector(getUserMeState);
    const statusData = useSelector(getUserStatusState);
    const dispath = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        dispath(getUserMeRequest());
    }, [dispath])

    useEffect(() => {
        form.setFieldsValue(meData)
    }, [meData])

    const onSubmit = () => {
        let data = form.getFieldsValue()
        //filter field have data
        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        dispath(updateUserMeRequest(data)).then((res) => {
        })
    }

    return (
        <Card>
            <Spin spinning={statusData == "loading" ? true : false}>
                <Form
                    layout="vertical"
                    form={form}
                >
                    <p className="font-bold text-xl pb-4">Chi tiết tài khoản</p>
                    <Row gutter={[16, 8]}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label={"Tên người dùng"}
                                name="username"
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label={"Họ và tên"}
                                name="full_name"
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label={"Email"}
                                name="email"
                            >
                                <Input disabled></Input>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={12}>
                            <Form.Item
                                label={"Giới tính"}
                                name="gender"
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label={"Số điện thoại"}
                                name="phone_number"
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={12}>
                            <Form.Item
                                label={"Ngày sinh"}
                                name="dob"
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={24}>
                            <Form.Item
                                label={"Mô tả"}
                                name="description"
                            >
                                <Input.TextArea
                                    rows={5}
                                ></Input.TextArea>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button type="primary" onClick={onSubmit}>Lưu thay đổi</Button>
                </Form>
            </Spin>
        </Card>
    )
}

export default Account