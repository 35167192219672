import DOMPurify from 'dompurify';
const Feeds = ({ timeline, langData }) => {
    
    return (
        <div className="flex flex-col min-w-64 lg:min-w-[500px] shadow-md rounded-lg bg-clip-border border p-8 min-h-[462px]">
            <h3 className="text-lg font-semibold text-slate-800 pb-8">{langData.dashboard_lang.timeline || <span>Dòng thời gian</span>}</h3>
            <div className="max-h-96 overflow-auto">
                <ul
                    aria-label="Changelog feed"
                    role="feed"
                    className="relative flex flex-col gap-12 py-12 pl-6 before:absolute before:top-0 before:left-6 before:h-full before:-translate-x-1/2 before:border before:border-dashed before:border-slate-200 after:absolute after:top-6 after:left-6 after:bottom-6 after:-translate-x-1/2 after:border after:border-slate-200 "
                >
                    {
                        timeline.map((event, index) => {
                            let description = DOMPurify.sanitize(event.description);
                            return <li
                                key={index}
                                role="article"
                                className="relative pl-6 before:absolute before:left-0 before:top-2 before:z-10 before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:bg-emerald-500 before:ring-2 before:ring-white"
                            >
                                <div className="flex flex-col flex-1 gap-4">
                                    <h4 className="text-lg font-medium text-emerald-500">
                                        {event.date}
                                    </h4>
                                    <p className="text-slate-800">
                                        {event.title}
                                    </p>
                                    <ul className="text-slate-500 marker:text-emerald-500">
                                        <li dangerouslySetInnerHTML={{ __html: description }}></li>
                                    </ul>
                                </div>
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default Feeds