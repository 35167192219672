import { Space, Table } from "antd"

const List = ({columnTable, dataList, onEditEvent}) => {
    const onDeleteEvent = () => {

    }

    const columns = [
        ...columnTable,
        {
            title: 'ACTION',
            key: 'action',
            fixed: 'right',
            render: (_, record) => (
              <Space size="middle">
                <a onClick={() => onEditEvent(record)}>Edit</a>
                <a onClick={onDeleteEvent}>Delete</a>
              </Space>
            ),
        },
    ]

    return <Table 
        rowKey={'id'}
        columns={columns}
        dataSource={dataList}
        scroll={{
          x: 'max-content',
        }}
    />
}

export default List