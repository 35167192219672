import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFetch } from "./API";
import { toast } from "react-toastify";

const sliceName = "line"
const initialState = {
    status: 'idle',
    lines: [],
}

export const getList = createAsyncThunk(
    sliceName+'/get',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const Slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getList.fulfilled, (state, action) => {
                state.status = 'idle';
                const { data } = action.payload
                state.lines = data
            })
    }
})

export const getState = (state) => state[sliceName]

export default Slice.reducer