import { useDispatch, useSelector } from "react-redux"
import BasicModule from "../../Commons/BasicModule"
import AuthCMS from "../../Layouts/AuthCMS"
import AuthCMSAntd from "../../Layouts/AuthCMSAntd"
import { getList, getState } from "../../Stores/Line/Slice"
import { useEffect } from "react"

// Edit
const modelField = ['name', 'description', 'status', 'start_date', 'end_date', 'cost', 'box_id', 'inteface', 'pppoe_username', 'pppoe_password', 'env']
const headerInfo = {
    title: "Line",
    description: "List line",
}
const dataList = [
    {id: 1, name: "Example 1"},
    {id: 2, name: "Example 2"}
]

// No Edit 
const columnTable = modelField.map((field) => ({
    title: field.toUpperCase(),
    dataIndex: field,
    key: field,
}))

const Line = () => {
    const data = useSelector(getState)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getList())
    }, [dispatch])

    return <AuthCMSAntd tabActive={"/line"}>
        <BasicModule
            headerInfo={headerInfo}
            columnTable={columnTable}
            dataList={data.lines}
        />
    </AuthCMSAntd>
}

export default Line