import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { autoRenewKeyFetch, createKeyFetch, deleteKeysFetch, getDetailKeyFetch, getKeysFetch, refreshKeysFetch, renewKeyFetch } from "./KeyAPI";

const initialState = {
    keys: [],
    state: 'idle',
    key: {},
    keyStatus: 'idle',
}

export const createKeyRequest = createAsyncThunk(
    'keys/create',
    async (data, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await createKeyFetch(token, data);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const getKeysRequest = createAsyncThunk(
    'keys/get',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getKeysFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const getDetailKeyRequest = createAsyncThunk(
    'keys/read',
    async (keyID, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getDetailKeyFetch(token, keyID);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const refreshKeysRequest = createAsyncThunk(
    'keys/refresh',
    async (keyID, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await refreshKeysFetch(token, keyID);
        if (response.status === 200) {
            // Success
            toast.success("Success refresh.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const deleteKeysRequest = createAsyncThunk(
    'keys/delete',
    async (keyID, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await deleteKeysFetch(token, keyID);
        if (response.status === 200) {
            // Success
            toast.success("Deleted.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const renewKeyRequest = createAsyncThunk(
    'keys/renew_post',
    async (keyID, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await renewKeyFetch(token, keyID);
        if (response.status === 200) {
            // Success
            toast.success("Renew Success.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const autoRenewKeyRequest = createAsyncThunk(
    'keys/renew_post',
    async (data, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await autoRenewKeyFetch(token, data.keyID, data.auto_renew);
        if (response.status === 200) {
            // Success
            toast.success("Update sucess.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const KeySlice = createSlice({
    name: 'keys',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(createKeyRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createKeyRequest.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(getKeysRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getKeysRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { data } = action.payload
                state.keys = data
            })
            .addCase(getDetailKeyRequest.pending, (state) => {
                state.key = {};
                state.keyStatus = 'loading';
            })
            .addCase(getDetailKeyRequest.fulfilled, (state, action) => {
                state.keyStatus = 'idle';
                state.key = action.payload;
            })
            .addCase(refreshKeysRequest.pending, (state) => {
                state.keyStatus = 'loading';
            })
            .addCase(refreshKeysRequest.fulfilled, (state, action) => {
                state.keyStatus = 'idle';
                state.key = action.payload;
            })
            .addCase(renewKeyRequest.pending, (state) => {
                state.keyStatus = 'loading';
            })
            .addCase(renewKeyRequest.fulfilled, (state) => {
                state.keyStatus = 'idle';
            })
    }
})

export const getKeysState = (state) => state.keys.keys

export const getKeyState = (state) => state.keys.key
export const getKeyStatusState = (state) => state.keys.keyStatus

export default KeySlice.reducer