import { createBrowserRouter } from "react-router-dom";
import Auth from "./Pages/Auth/Auth";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Auth/Login/Login";
import Key from "./Pages/Key/Key";
import Packages from "./Pages/Packages/Packages";
import Topup from "./Pages/Topup/Topup";
import Payment from "./Pages/Payment/Payment";
import Register from "./Pages/Auth/Register/Register";
import ForgetPass from "./Pages/Auth/ResetPass/ForgetPass";
import ResetPass from "./Pages/Auth/ResetPass/ResetPass";
import Verify from "./Pages/Auth/Verify/Verify";
import Me from "./Pages/Me/Me";
import Users from "./Pages/Users/Users";
import Location from "./Pages/Location/Location";
import Box from "./Pages/Box/Box";
import Line from "./Pages/Line/Line";
import Proxy from "./Pages/Proxy/Proxy";
import Doc from "./Pages/Doc/Doc";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Auth />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forget",
    element: <ForgetPass />,
  },
  {
    path: "/reset-password",
    element: <ResetPass />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
  {
    path: "/dashboard",
    element: <Auth component={Dashboard}/>,
  },
  {
    path: "/package",
    element: <Auth component={Packages}/>,
  },
  {
    path: "/key",
    element: <Auth component={Key}/>,
  },
  {
    path: "/topup",
    element: <Auth component={Topup}/>,
  },
  {
    path: "/payment",
    element: <Auth component={Payment}/>,
  },
  {
    path: "/me",
    element: <Auth component={Me}/>
  },
  {
    path: "/users",
    element: <Auth component={Users}/>
  },
  {
    path: "/location",
    element: <Auth component={Location}/>
  },
  {
    path: "/box",
    element: <Auth component={Box}/>
  },
  {
    path: "/line",
    element: <Auth component={Line}/>
  },
  {
    path: "/proxy",
    element: <Auth component={Proxy}/>
  },
  {
    path: "/doc",
    element: <Auth component={Doc}/>
  },
  {
    path: "*",
    element: <>U should not here ^^ it's not exist.</>
  }
]);