import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Stores/AuthSlice';
import topupReducer from './Stores/TopupSlice';
import packagesReducer from './Stores/PackageSlice';
import cyclesReducer from './Stores/CycleSlice';
import keyReducer from './Stores/KeySlice';
import paymentReducer from './Stores/PaymentSlice';
import userReducer from './Stores/UserSlice';
import voucherReducer from './Stores/VoucherSlice';
import dashboardReducer from './Stores/DashboardSlice';
import tokenReducer from './Stores/TokenSlice';
import locationReducer from './Stores/Location/Slice';
import boxReducer from './Stores/Box/Slice';
import lineReducer from './Stores/Line/Slice';
import proxiesReducer from './Stores/Proxy/Slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    topup: topupReducer,
    packages: packagesReducer,
    cycles: cyclesReducer,
    keys: keyReducer,
    payments: paymentReducer,
    user: userReducer,
    voucher: voucherReducer,
    dashboard: dashboardReducer,
    token: tokenReducer,
    location: locationReducer,
    box: boxReducer,
    line: lineReducer,
    proxies: proxiesReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
