import { useDispatch, useSelector } from "react-redux"
import BasicModule from "../../Commons/BasicModule"
import AuthCMS from "../../Layouts/AuthCMS"
import AuthCMSAntd from "../../Layouts/AuthCMSAntd"
import { getList, getState } from "../../Stores/Proxy/Slice"
import { useEffect } from "react"

// Edit
const modelField = ['name', 'description', 'status', 'ip_address', 'port', 'socks5', 'is_primary', 'line_id', 'env', 'refresh_status', 'country_code', 'country_name', 'city_name', 'region']
const headerInfo = {
    title: "Proxy",
    description: "List proxy",
}
const dataList = [
    { id: 1, name: "Example 1" },
    { id: 2, name: "Example 2" }
]

// No Edit 
const columnTable = modelField.map((field) => ({
    title: field.toUpperCase(),
    dataIndex: field,
    key: field,
}))

const Proxy = () => {
    const data = useSelector(getState)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getList())
    }, [dispatch])
    return <AuthCMSAntd tabActive={"/proxy"}>
        <BasicModule
            headerInfo={headerInfo}
            columnTable={columnTable}
            dataList={data.proxies}
        />
    </AuthCMSAntd>
}

export default Proxy