import { Col, Row } from "antd"
import List from "./List"
import Detail from "./Detail";
import { useState } from "react";

const BasicModule = ({ headerInfo, columnTable, dataList }) => {
    const { title, description } = headerInfo;
    const [isOpenDetail, setIsOpenDetail] = useState(false);
    const [dataDetail, setDataDetail] = useState({});

    const onEditEvent = (record) => {
        setDataDetail(record)
        setIsOpenDetail(true)
    }

    return (<Row>
        <Col xs={24}>
            {/* Header*/}
            <Row>
                <Col xs={24}>
                    <div className="w-full flex justify-between items-center mb-3 mt-1 pl-3">
                        <div>
                            <h3 className="text-lg font-bold text-slate-800">{title}</h3>
                            <p className="text-slate-500">{description}</p>
                        </div>
                        <div className="mr-3">
                            <div className="w-full max-w-sm min-w-[200px] relative">
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* List Table Component */}
            <Row>
                <Col xs={24}>
                    <List
                        columnTable={columnTable}
                        dataList={dataList}
                        onEditEvent={onEditEvent}
                    />
                </Col>
            </Row>
        </Col>
        {/* Detail Item */}
        <Detail 
            isOpen={isOpenDetail}
            setIsOpenDetail={setIsOpenDetail}
            dataDetail={dataDetail}
        />
    </Row>)
}

export default BasicModule