import React, { useEffect, useState } from 'react';
import { Modal, Select, Input, Button, InputNumber, Form, Flex, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getPackagesState } from '../../../../Stores/PackageSlice';
import { getCyclesState } from '../../../../Stores/CycleSlice';
import { getKeysRequest, renewKeyRequest } from '../../../../Stores/KeySlice';

const { Option } = Select;

const ExtendKeyModal = ({ isOpen, onClose, record }) => {
  const [visible, setVisible] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(110000);
  const [form] = Form.useForm();

  const cyclesData = useSelector(getCyclesState);
  const dispatch = useDispatch();

  useEffect(() => {
    if(record.cycle_id) {
      form.setFieldValue('package', record.cycle_id)
      handlePackageChange(record.cycle_id)
    }
  }, [record])

  const handleQuantityChange = (value) => {
    setQuantity(value);
    setTotalPrice(price * value);
  };

  const handleSubmit = (values) => {
    // console.log('Form Submitted:', values);
    dispatch(renewKeyRequest(record.id)).then((response) => {
      dispatch(getKeysRequest())
      onClose()
    });
  };

  const handlePackageChange = (id) => {
    let cycleRecord = cyclesData.find(cycle => cycle.id == id)
    setPrice(cycleRecord.price)
    setTotalPrice(cycleRecord.price * quantity);
  }

  return (
    <Modal
      title="Gia hạn key"
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col flex="auto">
            <Form.Item
              label="Gói"
              name="package"
            >
              <Select
                onChange={handlePackageChange}
                disabled
              >
                {cyclesData.map((cycle) => {
                  return <Option value={cycle.id} key={cycle.id}>
                    {cycle.name}
                  </Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={"140px"}>
            <Form.Item label="Số lượng chu kỳ" name="quantity" initialValue={1}>
              <InputNumber
                min={1}
                value={quantity}
                onChange={handleQuantityChange}
                style={{width: "100%"}}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Mã giảm giá" name="discountCode">
          <Input placeholder="Nhập Mã giảm giá" />
        </Form.Item>

        <div style={{ marginBottom: '16px' }}>
          <strong>Tổng cộng:</strong> {totalPrice.toLocaleString()} VND
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
            Xác Nhận
          </Button>
          <Button onClick={() => onClose()}>Hủy</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExtendKeyModal;
